
import PriceMap from "@/components/Map/PriceMap";






export const MapPanel = () => {
  return <div><PriceMap /></div>;
};
