import { create } from "zustand";
import { LatLngExpression } from "leaflet";
import { encrypt } from "@/helpers/encoding";
import { UsageTierOpts } from "@/types/frontend";
import Api from "@/utils/api";
import { v4 as uuidv4 } from "uuid";
import { decrypt } from "@/helpers/encoding";
export interface SessionData {
  sessionId: string;
  sessionStartTime: number;
  userId?: string; // Optional, if the session is associated with a logged-in user
  role?: string; // Optional, if the session is associated with a logged-in user
  homeinfo_zipCode: string;
  homeinfo_address: string;
  homeinfo_address_line1: string;
  homeinfo_address_line2: string;
  homeinfo_address_city: string;
  homeinfo_address_state: string;
  homeinfo_address_zip: string;
  homeinfo_place?: google.maps.places.PlaceResult;
  homeinfo_coords: LatLngExpression;
  homeinfo_usageTier: UsageTierOpts;
  homeinfo_estimatedUsage: number;
  homeinfo_homeSize: number;
  homeinfo_esiId: string;
  map_zoom: number;
  searches: string[];
  plansClickedIds: string[];
  filterHistory: any[];
  pageHistory: string[];
  createdAt: number; // Timestamp of when the session was created
  updatedAt: number; // Timestamp of the last update
  expiresAt: number; // Timestamp of when the session expires
  isLeftNavOpen: boolean;
  setIsLeftNavOpen: (isLeftNavOpen: boolean) => void;
}

export interface SessionAction {
  updateStateFromDataPack: (dataPack: string) => void;
  updateHomeInfo_zipCode: (
    homeinfo_zipCode: SessionData["homeinfo_zipCode"]
  ) => void;
  updateHomeInfo_address: (
    homeinfo_address: SessionData["homeinfo_address"]
  ) => void;
  updateHomeInfo_address_line1: (
    homeinfo_address_line1: SessionData["homeinfo_address_line1"]
  ) => void;
  updateHomeInfo_address_line2: (
    homeinfo_address_line2: SessionData["homeinfo_address_line2"]
  ) => void;
  updateHomeInfo_address_city: (
    homeinfo_address_city: SessionData["homeinfo_address_city"]
  ) => void;
  updateHomeInfo_address_state: (
    homeinfo_address_state: SessionData["homeinfo_address_state"]
  ) => void;
  updateHomeInfo_address_zip: (
    homeinfo_address_zip: SessionData["homeinfo_address_zip"]
  ) => void;
  updateHomeInfo_place: (homeinfo_place: SessionData["homeinfo_place"]) => void;
  updateHomeInfo_coords: (
    homeinfo_coords: SessionData["homeinfo_coords"]
  ) => void;
  updateHomeInfo_usageTier: (
    homeinfo_usageTier: SessionData["homeinfo_usageTier"]
  ) => void;
  updateHomeInfo_estimatedUsage: (
    homeinfo_estimatedUsage: SessionData["homeinfo_estimatedUsage"]
  ) => void;
  updateHomeInfo_homeSize: (
    homeinfo_homeSize: SessionData["homeinfo_homeSize"]
  ) => void;
  updateHomeInfo_esiId: (homeinfo_esiId: SessionData["homeinfo_esiId"]) => void;
  updateSearches: (searches: SessionData["searches"]) => void;
  updatePlansClickedIds: (
    plansClickedIds: SessionData["plansClickedIds"]
  ) => void;
  updateFilterHistory: (filterHistory: SessionData["filterHistory"]) => void;
  updatePageHistory: (pageHistory: SessionData["pageHistory"]) => void;
  updateCreatedAt: (createdAt: SessionData["createdAt"]) => void;
  updateUpdatedAt: (updatedAt: SessionData["updatedAt"]) => void;
  updateExpiresAt: (expiresAt: SessionData["expiresAt"]) => void;
  updateMapZoom: (map_zoom: SessionData["map_zoom"]) => void;
  startNewSession: () => void;
  setSessionId: (sessionId: string) => void;
}

export const useSessionStore = create<SessionData & SessionAction>(
  (set, get) => ({
    sessionId: "",
    sessionStartTime: 0,
    userId: "",
    role: "",
    homeinfo_zipCode: "",
    homeinfo_address: "",
    homeinfo_address_line1: "",
    homeinfo_address_line2: "",
    homeinfo_address_city: "",
    homeinfo_address_state: "",
    homeinfo_address_zip: "",
    homeinfo_place: undefined,
    homeinfo_coords: { lat: 30.2672, lng: -97.7431 },
    map_zoom: 6,
    homeinfo_usageTier: UsageTierOpts.MEDIUM,
    homeinfo_estimatedUsage: 0,
    homeinfo_homeSize: 0,
    homeinfo_esiId: "",
    searches: [],
    plansClickedIds: [],
    filterHistory: [],
    pageHistory: [],
    createdAt: 0,
    updatedAt: 0,
    expiresAt: 0,
    isLeftNavOpen: false,
    startNewSession: () => {
      set((state) => {
        return {
          ...state,
          sessionId: uuidv4(),
          sessionStartTime: Date.now(),
          createdAt: Date.now(),
          updatedAt: Date.now(),
          expiresAt: Date.now() + 1000 * 60 * 60 * 24 * 30, // 30 days from now
        };
      });
      localStorage.setItem("sessionId", get().sessionId);
      Api.startSession(makeDataPack(get().sessionId, get()))
        .catch(console.error)
        .then((userId) => {
          set((state) => {
            return {
              ...state,
              userId,
            };
          });
        });
    },
    updateStateFromDataPack: (dataPack: string) => {
      const data = JSON.parse(decrypt(dataPack));
      set((state) => ({
        ...state,
        ...data.sessiondata,
      }));
    },
    setEnrollmentDialogOpen: (enrollmentDialogOpen: boolean) => {
      set((state) => {
        const newState = { ...state, enrollmentDialogOpen };
        console.log("State", state);
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    setIsLeftNavOpen: (isLeftNavOpen: boolean) => {
      set((state) => {
        console.log("State", state);
        const newState = { ...state, isLeftNavOpen };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },

    updateSessionData: async (updates: Partial<SessionData>) => {
      set((state) => {
        console.log("State", state);
        const newState = { ...state, ...updates };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },

    updateHomeInfo_zipCode: (homeinfo_zipCode) => {
      set((state) => {
        const newState = { ...state, homeinfo_zipCode };
        console.log("State", state);
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_address: (homeinfo_address) => {
      set((state) => {
        const newState = { ...state, homeinfo_address };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_address_line1: (homeinfo_address_line1) => {
      set((state) => {
        const newState = { ...state, homeinfo_address_line1 };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_address_line2: (homeinfo_address_line2) => {
      set((state) => {
        const newState = { ...state, homeinfo_address_line2 };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_address_city: (homeinfo_address_city) => {
      set((state) => {
        const newState = { ...state, homeinfo_address_city };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_address_state: (homeinfo_address_state) => {
      set((state) => {
        const newState = { ...state, homeinfo_address_state };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_address_zip: (homeinfo_address_zip) => {
      set((state) => {
        const newState = { ...state, homeinfo_address_zip };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_place: (homeinfo_place) => {
      set((state) => {
        const newState = { ...state, homeinfo_place };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_coords: (homeinfo_coords) => {
      set((state) => {
        let map_zoom = 6; // Default zoom for state-level

        if (state.homeinfo_address_zip && !state.homeinfo_address_line1) {
          map_zoom = 8; // Zoom for zip code level
        } else if (state.homeinfo_address_line1) {
          map_zoom = 12; // Zoom for full address
        }

        const newState = { ...state, homeinfo_coords, map_zoom };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_usageTier: (homeinfo_usageTier) => {
      set((state) => {
        const newState = { ...state, homeinfo_usageTier };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_estimatedUsage: (homeinfo_estimatedUsage) => {
      set((state) => {
        const newState = { ...state, homeinfo_estimatedUsage };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_homeSize: (homeinfo_homeSize) => {
      set((state) => {
        const newState = { ...state, homeinfo_homeSize };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateHomeInfo_esiId: (homeinfo_esiId) => {
      set((state) => {
        const newState = { ...state, homeinfo_esiId };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateSearches: (searches) => {
      set((state) => {
        const newState = { ...state, searches };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updatePlansClickedIds: (plansClickedIds) => {
      set((state) => {
        const newState = { ...state, plansClickedIds };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateFilterHistory: (filterHistory) => {
      set((state) => {
        const newState = { ...state, filterHistory };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updatePageHistory: (pageHistory) => {
      set((state) => {
        const newState = { ...state, pageHistory };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateCreatedAt: (createdAt) => {
      set((state) => {
        const newState = { ...state, createdAt };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateUpdatedAt: (updatedAt) => {
      set((state) => {
        const newState = { ...state, updatedAt };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateExpiresAt: (expiresAt) => {
      set((state) => {
        const newState = { ...state, expiresAt };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    updateMapZoom: (map_zoom) => {
      set((state) => {
        const newState = { ...state, map_zoom };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
    setSessionId: (sessionId: string) => {
      set((state) => {
        const newState = { ...state, sessionId };
        Api.updateSessionData(state.sessionId, newState).catch(console.error);
        return newState;
      });
    },
  })
);

export function makeDataPack(sessionId: string, data: SessionData) {
  const dataPack = { sessionId, dataPack: encrypt(JSON.stringify(data)) };
  return dataPack;
}
