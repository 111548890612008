import React from 'react';
import SearchBar2 from '@/components/individual/newSearch';

const GetStarted = (): JSX.Element => {
  return (
    <div className="container mx-auto px-4">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">
        Start Saving With Get Electricity Today
      </h2>
      <p className="text-lg md:text-xl text-gray-600 text-center mb-8">
        Find the best plan in minutes. Enter your zip code to get started.
      </p>
      <div className="w-[90%] max-w-[900px]mx-auto">
        <SearchBar2 />
      </div>
    </div>
  );
};

export default GetStarted;
