import useAuthStore from "@/providers/authStore";
import { Navigate, useNavigate } from "react-router-dom";

const Profile = () => {
  const { isAuthenticated, user } = useAuthStore();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    return <Navigate to="/auth" />;
  }

  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <h1>{user?.contact?.name}</h1>
    </div>
  );
};

export default Profile;
