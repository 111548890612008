import { PlanInfo } from '@/types/plan';

const PlanCards = ({plan}: {plan: PlanInfo}): JSX.Element => {
  const price500 = (plan?.["plan-pricing"]?.["kwh-500"] > 1 ? plan?.["plan-pricing"]?.["kwh-500"] : plan?.["plan-pricing"]?.["kwh-500"]*100).toFixed(1);
  const price1000 = (plan?.["plan-pricing"]?.["kwh-1000"] > 1 ? plan?.["plan-pricing"]?.["kwh-1000"] : plan?.["plan-pricing"]?.["kwh-1000"]*100).toFixed(1);
  const price2000 = (plan?.["plan-pricing"]?.["kwh-2000"] > 1 ? plan?.["plan-pricing"]?.["kwh-2000"] : plan?.["plan-pricing"]?.["kwh-2000"]*100).toFixed(1);

  if (!plan) {
    return <></>;
  }

  const PriceCard = ({ usage, price }: { usage: string; price: string }) => (
    <div className="w-1/3 h-full flex flex-col justify-center items-center transition-all duration-500 ease-in-out hover:w-4/5 hover:z-10 bg-white rounded-lg shadow-md cursor-pointer">
      <div className="flex flex-col items-center p-4">
        <h3 className="text-lg font-normal">{usage}</h3>
        <p className="text-sm font-normal">kWh</p>
        <p className="text-lg text-primary price transition-all duration-500 ease-in-out hover:text-3xl">{price}¢</p>
      </div>
    </div>
  );

  return (
    <div className="p-4">
      <div className="flex flex-row justify-center items-center cursor-pointer">
        <PriceCard usage="500" price={price500} />
        <PriceCard usage="1000" price={price1000} />
        <PriceCard usage="2000" price={price2000} />
      </div>
    </div>
  );
};

export default PlanCards;
