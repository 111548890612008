import React, { useEffect, useRef } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { config } from "@/config";
import { Library } from "@googlemaps/js-api-loader";
import { useSessionStore } from "@/providers/store";
import { LatLngLiteral } from "leaflet";
import "./addressAutocomplete.css";
import Api from "@/utils/api";

interface AddressAutocompleteProps {
  onSelectAddress: (address: string) => void;
}

const libraries = ["places"];

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onSelectAddress }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries: libraries as Library[],
  });

  const updateHomeInfo_address = useSessionStore((state) => state.updateHomeInfo_address);
  const updateHomeInfo_coords = useSessionStore((state) => state.updateHomeInfo_coords);
  const updateHomeInfo_address_city = useSessionStore((state) => state.updateHomeInfo_address_city);
  const updateHomeInfo_address_state = useSessionStore((state) => state.updateHomeInfo_address_state);
  const updateHomeInfo_address_zip = useSessionStore((state) => state.updateHomeInfo_address_zip);
  const updateHomeInfo_address_line1 = useSessionStore((state) => state.updateHomeInfo_address_line1);
  const updateHomeInfo_place = useSessionStore((state) => state.updateHomeInfo_place);
  const updateHomeInfo_esiId = useSessionStore((state) => state.updateHomeInfo_esiId);
  useEffect(() => {
    if (!isLoaded || !inputRef.current) return;

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ["formatted_address", "geometry", "address_components"],
      componentRestrictions: { country: "us" },
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.formatted_address && place.geometry) {
        console.log("PLACE", place);
        const { formatted_address, geometry, address_components } = place;

        const address = formatted_address;
        const coords = {
          lat: geometry.location?.lat(),
          lng: geometry.location?.lng(),
        };

        let streetNumber = "";
        let streetName = "";
        let city = "";
        let state = "";
        let zip = "";

        address_components?.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            city = component.long_name;
          }
          if (types.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (types.includes("postal_code")) {
            zip = component.long_name;
          }
          if (types.includes("street_number")) {
            streetNumber = component.long_name;
          }
          if (types.includes("route")) {
            streetName = component.long_name;
          }
        });

        updateHomeInfo_place(place);
        updateHomeInfo_coords(coords as LatLngLiteral);
        updateHomeInfo_address_city(city);
        updateHomeInfo_address_state(state);
        updateHomeInfo_address_zip(zip);
        if (streetNumber && streetName) {
          updateHomeInfo_address_line1(`${streetNumber} ${streetName}`);
          updateHomeInfo_address(address);
          Api.findEsiId({
            rawAddress: address,
            addressLine: streetNumber + " " + streetName,
            city: city,
            state: state,
            zip: zip,
            coords: coords as LatLngLiteral,
          }).then((esiId) => {
            updateHomeInfo_esiId(esiId);
          });
        }

        onSelectAddress(zip);
      }
    });
  }, [
    isLoaded,
    onSelectAddress,
    updateHomeInfo_address,
    updateHomeInfo_coords,
    updateHomeInfo_address_city,
    updateHomeInfo_address_state,
    updateHomeInfo_address_zip,
    updateHomeInfo_place
  ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      onSelectAddress('');
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder="Enter your address"
      className="grow w-full outline-none bg-inherit mx-6 rounded-lg"
      onChange={handleInputChange}
    />
  );
};

export default AddressAutocomplete;
