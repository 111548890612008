import { useState, useEffect } from "react";
import { cn } from "@/lib/utils";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { PlanInfo } from "@/types/plan";
import Map from "@/components/Map/Map";
import { renderChips } from "@/views/Plans/Plans";
import { UsageTiers, usePlansStore } from "@/providers/planStore";
import { useEnrollStore } from "@/providers/enrollStore";
import PlanDetailsInfoSection from "@/views/Plans/ProductDialog/components/PlanDetailsInfoSection";
import { StarIcon } from "@heroicons/react/20/solid";
import StatsCard from "@/views-auth/Dashboard/Panels/stats/StatsCard";
import { linkMap } from "@/utils/link-map";
import { HomeReccs } from "./components/homeReccs";
import { GoogleIcon } from "@/assets/google-icon";
import {
  CircleAlert,
  ThumbsUp,
  ThumbsDown,
  HousePlug,
  RefreshCcw,
} from "lucide-react";
import { DetailsHeader } from "./components/DetailsHeader";
import UsageTier from "@/views/Plans/Filters/components/UsageTier/UsageTier";
import IsThisRightForYou from "./components/IsThisRightForYou";

const makeFAQ = (plan: PlanInfo) => {
  const faqKeys: {
    [key: string]: string;
  } = {
    newCustomer: "Is this plan available to new customers?",
    prepaid: "Is this plan a prepaid plan?",
    timeOfUse: "Does this plan have time-of-use pricing?",
    termLength: "What is the term length of this plan?",
    usageCredits: "Are there usage credits included in this plan?",
    sellingPoints: "What are the selling points of this plan?",
    cancellationFee: "What is the cancellation fee for this plan?",
    importantDetails: "What are the important details of this plan?",
    minUsageFeesCredits:
      "Are there minimum usage fees or credits specified in this plan?",
  };

  return Object.keys(faqKeys).map((key) => {
    if (plan?.["ai-analysis"]?.[key]?.["notes"]) {
      return {
        question: faqKeys[key],
        answer: plan["ai-analysis"][key]["notes"],
      };
    }
    return undefined;
  });
};

export const renderStars = (rating: number) => {
  return (
    <div className="flex items-center">
      {[0, 1, 2, 3, 4].map((index) => {
        const fillPercentage = Math.max(
          0,
          Math.min(100, (rating - index) * 100)
        );

        return (
          <span
            key={index}
            className="relative inline-block items-center w-5 h-5"
          >
            {/* Background star (gray) */}
            <StarIcon className="absolute w-5 h-5 text-gray-200" />

            {/* Filled star with width based on rating */}
            <div
              style={{ width: `${fillPercentage}%` }}
              className="overflow-hidden absolute"
            >
              <StarIcon className="w-5 h-5 text-yellow-400" />
            </div>
          </span>
        );
      })}
      <span className="ml-2 text-sm text-gray-500">{rating.toFixed(1)}</span>
    </div>
  );
};

export default function ProductDetails({
  open,
  onClose,
  plan,
}: {
  open: boolean;
  onClose: () => void;
  plan: PlanInfo | null;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="relative z-10 w-full h-full"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full min-w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full max-w-[100vw] sm:max-w-[80vw] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <ProductDetailsContent plan={plan} onClose={onClose} />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Fragment } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function ProductDetailsContent({
  plan,
  onClose,
}: {
  plan: PlanInfo | null;
  onClose: () => void;
}) {
  const { filters, setUsageTier } = usePlansStore();
  const [usage, setUsage] = useState<number>(1000);
  const [selectedTier, setSelectedTier] = useState(
    filters.usageTier.value || UsageTiers.MEDIUM
  );

  useEffect(() => {
    setSelectedTier(filters.usageTier.value || UsageTiers.MEDIUM);
  }, [filters.usageTier.value]);

  return (
    <div className="bg-white">
      <div className="mx-auto px-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* Product */}
        <Button variant="outline" onClick={onClose} className="sm:mb-4">
          Back to all plans
        </Button>
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16 direction-reverse">
          {/* Product image */}

          <div className="hidden lg:block lg:col-span-4 lg:row-end-1">
            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
              <Map />
            </div>
          </div>

          {/* Product details */}
          <div className="mx-auto mt-4 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col w-full items-center justify-center pb-4 border-b border-gray-200">
              <div className="flex flex-row w-full items-center justify-start gap-4">
                <h1 className="text-2xl font-bold">
                  {plan?.info["plan-name"]}
                </h1>
                <img
                  src={plan?.retailer?.logo}
                  alt={plan?.retailer?.name}
                  className="w-24 h-24"
                />
              </div>
              <div className="flex flex-col p-2 lg:flex-row w-full items-center justify-start gap-4">
                <p className="text-gray-500">{plan?.retailer?.name}</p>
                {plan?.retailer?.reviews?.["google"]?.["average-rating"] && (
                  <div className="flex flex-row items-center justify-start gap-2">
                    <GoogleIcon className="w-5 h-5" />
                    <div className="flex flex-row items-center justify-start">
                      {renderStars(
                        plan.retailer.reviews["google"]["average-rating"]
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <p className="mt-6 text-gray-500">
              {plan?.["ai-analysis"]?.description
                ? plan["ai-analysis"].description
                : plan?.info?.["plan-description"]}
            </p>

            <div className="mt-10 grid grid-rows-1 lg:grid-cols-2 gap-x-6 gap-y-4">
              <Card>
                <CardHeader className="flex flex-row items-center justify-center space-y-0 pb-2">
                  <CardTitle className="text-md font-medium">
                    <div className="text-4xl lg:text-5xl font-bold text-mainBlue">
                      {
                        plan?.["plan-pricing"]?.[
                          `kwh-${filters.usageTier.value}`
                        ]
                      }
                      ¢
                      <span className="block text-sm text-gray-500">
                        &nbsp; / kWh
                      </span>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col items-center justify-center">
                  <div className="text-xs pb-2 text-gray-500">
                    Base rate for {filters.usageTier.value} kWh
                  </div>
                  <div className="flex flex-row">
                    {Object.values(UsageTiers).map((key: UsageTiers) => (
                      <Button
                        key={key}
                        value={key}
                        onClick={() => {
                          setUsageTier({ enabled: true, value: key });
                        }}
                        className={cn(
                          `border border-gray-300 rounded-md p-2 bg-white text-black`,
                          {
                            "bg-mainBlue text-white": selectedTier === key,
                          }
                        )}
                      >
                        {key}
                      </Button>
                    ))}
                  </div>
                </CardContent>
              </Card>

              <div className="flex flex-col justify-center items-center gap-4">
                {/* <Button variant="outline" className="w-full">
                  Generate an Estimate
                </Button> */}
                <Button className="w-full text-lg">Sign Up</Button>
              </div>
            </div>
            {plan?.["ai-analysis"]?.["homeSizeRecommendations"] && (
              <HomeReccs homeSizes={plan["ai-analysis"]["homeSizeRecommendations"]} />
            )}
            {plan?.["ai-analysis"]?.["importantDetails"] && (
              <div className="flex flex-col my-8 gap-4">
                <h3 className="text-sm font-medium text-gray-900">
                  Who is this plan best for?
                </h3>
                <ul className="text-sm text-gray-500 ml-4 space-y-2">
                  {plan["ai-analysis"]["bestFor"].map(
                    (detail: string, idx: number) => (
                      <li key={idx} className="flex flex-row items-start gap-2">
                        <HousePlug className="w-6 h-6 text-gray-500 flex-shrink-0 mt-1" />
                        <span>{detail}</span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            {plan?.["ai-analysis"]?.["importantDetails"] && (
              <div className="flex flex-col my-8 gap-4">
                <h3 className="text-sm font-medium text-gray-900">
                  Important Details
                </h3>
                <ul className="text-sm text-gray-500 ml-4 space-y-2">
                  {plan["ai-analysis"]["importantDetails"].map(
                    (detail: string, idx: number) => (
                      <li key={idx} className="flex flex-row items-start gap-2">
                        <CircleAlert className="w-4 h-4 text-gray-500 flex-shrink-0 mt-1" />
                        <span>{detail}</span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {plan?.["ai-analysis"]?.["pros"] && (
              <div className="flex flex-col my-8 gap-4">
                <h3 className="text-sm font-medium text-gray-900">Pros</h3>
                <ul className="text-sm text-gray-500 ml-4">
                  {plan["ai-analysis"]["pros"].map(
                    (pro: string, idx: number) => (
                      <li key={idx} className="flex flex-row items-center p-2">
                        <ThumbsUp className="w-4 h-4 text-gray-500 mr-2" />
                        {pro}
                      </li>
                    )
                  )}
                </ul>
                <h3 className="text-sm font-medium text-gray-900">Cons</h3>
                <ul className="text-sm text-gray-500 ml-4">
                  {plan["ai-analysis"]["cons"] &&
                    plan["ai-analysis"]["cons"].map(
                      (con: string, idx: number) => (
                        <li
                          key={idx}
                          className="flex flex-row items-center p-2"
                        >
                          <ThumbsDown className="w-4 h-4 text-gray-500 mr-2" />
                          {con}
                        </li>
                      )
                    )}
                </ul>
              </div>
            )}

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Features</h3>
              <div className="p-4 sm:p-6 mt-4 text-gray-500">
                {plan && renderChips(plan)}
              </div>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Terms</h3>
              <p className="mt-4 text-sm text-gray-500"></p>
              <div className="flex flex-row justify-start items-start gap-2 mt-4">
                <Button variant="outline">EFL</Button>
                <Button variant="outline">YRAC</Button>
                <Button variant="outline">TOS</Button>
              </div>
            </div>

            <div className="hidden lg:block mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Share</h3>
              <ul role="list" className="mt-4 flex items-center space-x-6">
                <li>
                  <a
                    href="#"
                    className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Share on Facebook</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5"
                    >
                      <path
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Share on Instagram</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className="h-6 w-6"
                    >
                      <path
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Share on X</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5"
                    >
                      <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col items-left justify-center">
              <IsThisRightForYou />
              <div className="mt-4 lg:hidden">
                <Map />
              </div>
            </div>
            <TabGroup>
              <div className="border-b border-gray-200">
                <TabList className="-mb-px flex space-x-8">
                  <Tab className="whitespace-nowrap border-b-2 border-transparent py-2 text-sm font-medium text-gray-700 hover:border-gray-300 hover:text-gray-800 data-[selected]:border-mainBlue data-[selected]:text-mainBlue">
                    Details
                  </Tab>
                  <Tab className="whitespace-nowrap border-b-2 border-transparent py-2 text-sm font-medium text-gray-700 hover:border-gray-300 hover:text-gray-800 data-[selected]:border-mainBlue data-[selected]:text-mainBlue">
                    Reviews
                  </Tab>
                  <Tab className="whitespace-nowrap border-b-2 border-transparent py-2 text-sm font-medium text-gray-700 hover:border-gray-300 hover:text-gray-800 data-[selected]:border-mainBlue data-[selected]:text-mainBlue">
                    FAQ
                  </Tab>
                </TabList>
              </div>
              <TabPanels as={Fragment}>
                <TabPanel className="my-10">
                  <h3 className="sr-only">Details</h3>
                  {plan && (
                    <div className="flex flex-col gap-4">
                      <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
                        How your bill is calculated for 
                        <div className="flex items-center gap-2">
                          <Input
                            type="number"
                            min="0"
                            max="99999"
                            step="100"
                            placeholder="Monthly Usage (kWh)"
                            defaultValue={1000}
                            value={usage}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9]/g, "");
                              setUsage(Number(value) || 0);
                            }}
                            className="w-18 h-10"
                          />
                          <span className="text-sm text-gray-500">kWh</span>
                        </div>
                        Usage
                        {usage > 99000 && (
                          <p className="text-lg">
                            &#128558;
                          </p>
                        )}
                      </h3>
                      <PlanDetailsInfoSection planInfo={plan} usage={usage} />
                    </div>
                  )}
                  {/* 
                  {plan?.retailer?.reviews &&
                    // plan.retailer.reviews.featured.map((review, reviewIdx) => (
                    //   <div
                    //     key={review.id}
                    //   className="flex space-x-4 text-sm text-gray-500"
                    // >
                    //   <div className="flex-none py-10">
                    //     <img
                    //       alt=""
                    //       src={review.avatarSrc}
                    //       className="h-10 w-10 rounded-full bg-gray-100"
                    //     />
                    //   </div>
                    //   <div
                    //     className={cn(
                    //       reviewIdx === 0 ? "" : "border-t border-gray-200",
                    //       "py-10"
                    //     )}
                    //   >
                    //     <h3 className="font-medium text-gray-900">
                    //       {review.author}
                    //     </h3>
                    //     <p>
                    //       <time dateTime={review.datetime}>{review.date}</time>
                    //     </p>

                    //     <div className="mt-4 flex items-center">
                    //       {[0, 1, 2, 3, 4].map((rating) => (
                    //         <StarIcon
                    //           key={rating}
                    //           aria-hidden="true"
                    //           className={cn(
                    //             review.rating > rating
                    //               ? "text-yellow-400"
                    //               : "text-gray-300",
                    //             "h-5 w-5 shrink-0"
                    //           )}
                    //         />
                    //       ))}
                    //     </div>
                    //     <p className="sr-only">
                    //       {review.rating} out of 5 stars
                    //     </p>

                    //     <div
                    //       dangerouslySetInnerHTML={{ __html: review.content }}
                        className="prose prose-sm mt-4 max-w-none text-gray-500"
                      />
                    </div>
                  ))} */}
                </TabPanel>

                <TabPanel className="text-sm text-gray-500">
                  <h3 className="sr-only">Customer Reviews</h3>

                  <dl></dl>
                </TabPanel>

                <TabPanel className="">
                  <h3 className="sr-only">Frequently Asked Questions</h3>
                  {plan &&
                    makeFAQ(plan)
                      .filter(
                        (faq): faq is { question: string; answer: string } =>
                          faq !== undefined &&
                          typeof faq?.question === "string" &&
                          faq?.question.length > 0 &&
                          typeof faq?.answer === "string" &&
                          faq?.answer.length > 0
                      )
                      .map((faq) => (
                        <div
                          key={faq.question}
                          className="my-4 border-b border-gray-200"
                        >
                          <h4 className="text-gray-900">{faq?.question}</h4>
                          <p className="text-gray-500 ml-4 mb-2">
                            {faq?.answer}
                          </p>
                        </div>
                      ))}
                  {/* <div
                    dangerouslySetInnerHTML={{ __html: license.content }}
                    className="prose prose-sm max-w-none text-gray-500"
                  /> */}
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      </div>
    </div>
  );
}
