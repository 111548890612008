import React, { useState, useEffect } from "react";
import { usePlansStore } from "@/providers/planStore";
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';

const FilterRetailers = (): JSX.Element => {
  const { filters, updateFilteredPlans } = usePlansStore();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // Set all retailers as selected by default
    if (filters.retailers.options && filters.retailers.values.length === 0) {
      updateFilteredPlans();
    }
  }, [filters.retailers.options]);

  const handleRetailerClick = (retailer: string) => {
    const currentValues = filters.retailers.values;
    let newValues: string[];

    if (currentValues.includes(retailer)) {
      newValues = currentValues.filter((value) => value !== retailer);
    } else {
      newValues = [...currentValues, retailer];
    }

    updateFilteredPlans();
  };

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>
        <Button variant="ghost" className="w-full justify-between">
          <span className="font-bold">
            Retailers:{' '}
            {/* Uncomment and adjust if needed
            <span>
              {filters.retailers.values.length === filters.retailers.options?.length
                ? 'All'
                : filters.retailers.values.join(', ') || ''}
            </span>
            */}
          </span>
          {open ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-col space-y-1 mt-2">
          {filters.retailers.options?.map((retailer) => (
            <Button
              key={retailer}
              onClick={() => handleRetailerClick(retailer)}
              variant={filters.retailers.values.includes(retailer) ? "default" : "outline"}
              size="sm"
              className="justify-start"
            >
              {retailer}
            </Button>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default FilterRetailers;
