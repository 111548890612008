import React, { useState, useEffect } from 'react';
import { usePlansStore } from '@/providers/planStore';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';

const FilterFeatures = (): JSX.Element => {
  const { filters, updateFilteredPlans } = usePlansStore();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // Set all features as selected by default
    if (filters.features.options && filters.features.values.length === 0) {
      updateFilteredPlans();
    }
  }, [filters.features.options]);

  const handleFeatureClick = (feature: string) => {
    const currentValues = filters.features.values;
    let newValues: string[];

    if (currentValues.includes(feature)) {
      newValues = currentValues.filter((value) => value !== feature);
    } else {
      newValues = [...currentValues, feature];
    }

    updateFilteredPlans();
  };

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>
        <Button variant="ghost" className="w-full justify-between">
          <span className="font-bold">
            Features:{' '}
            <span>
              {filters.features.values.length === filters.features.options?.length
                ? 'All'
                : filters.features.values.join(', ') || ''}
            </span>
          </span>
          {open ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-row flex-wrap gap-2 mt-2">
          {filters.features.options?.map((feature) => (
            <Button
              key={feature}
              onClick={() => handleFeatureClick(feature)}
              variant={filters.features.values.includes(feature) ? "default" : "outline"}
              size="sm"
            >
              {feature}
            </Button>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default FilterFeatures;
