import { Lightbulb as LightbulbIcon, MapPin as MapPinIcon, Scale as ScaleIcon } from "lucide-react";

const services = [
  {
    title: "Enter Your Zip Code",
    subtitle:
      "Get instant access to the best energy plans and electricity rates in your area.",
    icon: <MapPinIcon size={48} />,
  },
  {
    title: "Compare Plans & Rates",
    subtitle:
      "Low rates, green energy, or flexible terms - choose what's right for you",
    icon: <ScaleIcon size={48} />,
  },
  {
    title: "Get Connected",
    subtitle:
      "Confirm your energy plan and get connected in minutes, no downtime.",
    icon: <LightbulbIcon size={48} />,
  },
];

const Services = (): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-center text-center py-24">
      <div className="mb-12">
        <h3 className="text-2xl font-bold text-primary mb-2">
          HOW IT WORKS
        </h3>
        <h2 className="text-xl lg:text-3xl font-medium text-gray-900">
          Get The Right Plan For Your Home
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {services.map((item, i) => (
          <div key={i} className="p-6">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 mb-4 bg-primary/10 text-[#377DFF] rounded-full flex items-center justify-center">
                {item.icon}
              </div>
              <h4 className="text-xl lg:text-2xl font-medium mb-2">
                {item.title}
              </h4>
              <p className="text-xs lg:text-base text-gray-600">
                {item.subtitle}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
