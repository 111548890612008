import { config } from "@/config";
import { Contact } from "@/types/contact";
import { Role as RoleType } from "@/types/roles";
import { User } from "@/types/user";
import { create } from "zustand";
import Api from "@/utils/api";

export interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  uid: string | null;
  user: User | null;
  role: string[] | null;
  sessionId: string | null;
  authPanelVisible: boolean;
  token: string | null;
}

export interface AuthActions {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setUid: (uid: string | null) => void;
  setUser: (user: User | null) => void;
  setRole: (role: string | string[] | null) => void;
  addRole: (role: string) => void;
  setSessionId: (sessionId: string | null) => void;
  setToken: (token: string | null) => void;
  login: (
    username: string,
    password: string
  ) => Promise<{ valid: boolean; redirect: string }>;
  register: (
    username: string,
    password: string
  ) => Promise<{ valid: boolean; redirect: string }>;
  logout: () => Promise<void>;
  loadUser: () => Promise<void>;
  updateUserData: (data: any) => Promise<void>;
  getUserData: () => Promise<any>;
  verifyCode: (code: string) => { valid: boolean; redirect: string };
  checkToken: () => Promise<boolean>;
}

const useAuthStore = create<AuthState & AuthActions>((set, get) => ({
  isAuthenticated: false,
  isLoading: false,
  uid: null,
  user: null,
  role: null,
  sessionId: null,
  authPanelVisible: false,
  token: null,

  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setUid: (uid) => set({ uid }),
  setUser: (user) => set({ user }),
  setToken: (token) => set({ token }),
  setSessionId: (sessionId: string | null) => set({ sessionId }),
  setAuthPanelVisible: (authPanelVisible: boolean) => set({ authPanelVisible }),

  setRole: (role: string | string[] | null) => {
    if (!role) {
      set({ role: null });
      return;
    }
    set((state) => {
      if (!state.role) {
        return { role: Array.isArray(role) ? role : [role] };
      }
      if (Array.isArray(role)) {
        return { role };
      }
      return { role: [role] };
    });
  },

  addRole: (role: string) =>
    set((state) => {
      if (!state.role?.includes(role)) {
        if (Array.isArray(state.role)) {
          return { role: [...state.role, role] };
        }
        return { role: [role] };
      }
      return state;
    }),

  login: async (username: string, password: string) => {
    try {
      set({ isLoading: true });
      const response = await Api.login(
        username,
        password,
        get().sessionId || ""
      );

      if (response) {
        const { token, userId, role } = response;
        set({
          isAuthenticated: true,
          uid: userId,
          role: Array.isArray(role) ? role : [role],
          token,
        });
        await get().loadUser();
        return { valid: true, redirect: "/dashboard" };
      }
      return { valid: false, redirect: "/auth" };
    } catch (error) {
      console.error("Login error:", error);
      return { valid: false, redirect: "/auth" };
    } finally {
      set({ isLoading: false });
    }
  },

  register: async (username: string, password: string) => {
    try {
      set({ isLoading: true });
      const response = await Api.register(
        username,
        password,
        get().sessionId || undefined
      );

      if (response) {
        return { valid: true, redirect: "/auth/login" };
      }
      return { valid: false, redirect: "/auth/register" };
    } catch (error) {
      console.error("Registration error:", error);
      return { valid: false, redirect: "/auth/register" };
    } finally {
      set({ isLoading: false });
    }
  },

  logout: async () => {
    try {
      await Api.logout();
      set({
        isAuthenticated: false,
        uid: null,
        user: null,
        role: null,
        sessionId: null,
        token: null,
      });
      localStorage.removeItem("authToken");
    } catch (error) {
      console.error("Logout error:", error);
    }
  },

  loadUser: async () => {
    try {
      const uid = get().uid;
      if (!uid) return;

      const userData = await Api.getUserData(uid);
      if (userData) {
        set({ user: userData.data });
      }
    } catch (error) {
      console.error("Error loading user:", error);
    }
  },

  updateUserData: async (data: any) => {
    try {
      const uid = get().uid;
      if (!uid) return;

      await Api.updateUserData(uid, data);
      await get().loadUser();
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  },

  getUserData: async () => {
    try {
      const uid = get().uid;
      if (!uid) return null;

      const userData = await Api.getUserData(uid);
      return userData?.data || null;
    } catch (error) {
      console.error("Error getting user data:", error);
      return null;
    }
  },
  verifyCode: (code: string) => {
    return { valid: false, redirect: "/auth" };
  },
  checkToken: async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.log("No token");
      return false;
    }

    try {
      const response = await Api.checkToken(token);
      if (response) {
        const { userId, role, user, sessionData } = response;
        set({
          isAuthenticated: true,
          uid: userId,
          role: Array.isArray(role) ? role : [role],
          user,
          token,
          sessionId: sessionData.sessionId,
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error("Token check error:", error);
      localStorage.removeItem("authToken");
      return false;
    }
  },
}));

export default useAuthStore;
