/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const Teaser = (): JSX.Element => {
  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-1/2 flex items-center">
          <div>
            <p className="uppercase font-medium text-secondary mb-2">
              Flexible options
            </p>
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Don't worry about{' '}
              <span className="text-primary">pricing</span>
            </h2>
            <p className="text-lg text-gray-600">
              We have options for every budget.
              <br />
              And plans for every type of home, apartment, or business.
              <br />
              Let us help you find the best plan for your situation.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <div className="max-w-[490px] w-full">
            <img
              src="https://storage.googleapis.com/getelectricity-assets/images/users/users.avif"
              alt="Users"
              className="w-full h-full rounded-lg dark:brightness-75"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teaser;
