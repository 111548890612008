import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import styles from './IconScroll.module.css';
import Api from '@/utils/api';
import { Retailer } from '@/types/plan';
import cn from 'classnames';

interface ImageStatus {
  url: string;
  isLoaded: boolean;
  hasError: boolean;
}

const IconScroll: React.FC = () => {
  const [images, setImages] = useState<ImageStatus[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const allRetailers = await Api.getRetailers();
        console.log('Retailers:', allRetailers);

        const imagePaths = allRetailers
          .map((retailer: Retailer) => retailer.logo)
          .filter((logo: string | undefined) => logo !== undefined) as string[];
        
        console.log('Image paths:', imagePaths);
        
        // Create repeated array with status for each image
        const repeatedImages = Array(4)
          .fill(imagePaths)
          .flat()
          .map(url => ({
            url,
            isLoaded: false,
            hasError: false
          }));
        
        console.log('Repeated images:', repeatedImages);
        setImages(repeatedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  const handleImageLoad = (index: number) => {
    console.log('Image loaded:', index);
    setImages(prev => prev.map((img, i) => 
      i === index ? { ...img, isLoaded: true } : img
    ));
  };

  const handleImageError = (index: number) => {
    console.log('Image error:', index);
    setImages(prev => prev.map((img, i) => 
      i === index ? { ...img, hasError: true } : img
    ));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (images.length === 0) {
    return <div></div>;
  }

  return (
    <div className={styles.iconScrollContainer}>
      <Marquee>
        {images.map((image, index) => (
          <div key={index} className={styles.imageWrapper}>
            {!image.hasError ? (
              <img
                src={image.url}
                alt={`icon-${index}`}
                className={cn(
                  styles.iconImage,
                  {'opacity-0': !image.isLoaded},
                  'transition-opacity duration-300'
                )}
                onLoad={() => handleImageLoad(index)}
                onError={() => handleImageError(index)}
              />
            ) : (
              <div className={styles.imagePlaceholder}>
                Failed to load
              </div>
            )}
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default IconScroll;
