import {
    SidebarInset,
    SidebarProvider,
    SidebarTrigger,
} from "@/components/ui/sidebar";
import useAuthStore from "@/providers/authStore";
import { AppSidebar } from "./app-sidebar";
import { Outlet, Navigate } from "react-router-dom";

export default function LayoutSidebar({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { isAuthenticated } = useAuthStore();
  if (!isAuthenticated) {
    return <Navigate to="/auth" />;
  }
  return (
    <SidebarProvider className="h-full w-full overflow-auto bg-stone-500">
      <AppSidebar />
      <SidebarInset className="bg-stone-500 rounded-lg">
        <header className="flex h-16 shrink-0 items-center gap-2 bg-background rounded-t-lg">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
          </div>
        </header>
        <div className="flex flex-1 flex-col gap-4 p-4 pt-0 bg-background rounded-b-lg">
          <Outlet />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
