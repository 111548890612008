/// <reference types="vite/client" />
import { EnrollData, makeEnrollPack } from "@/providers/enrollStore";
import { makeDataPack, SessionData } from "@/providers/store";
import { SearchInfo } from "@/types/auth/userInfo";
import { pgPlanInfo, ZipInfo_T } from "@/types/plan";
import { config } from "@/config";
import axios from "axios";
import { RSSFeed_T } from "@/types/rss";
import { encrypt } from "@/helpers/encoding";

const Api = {
  apiUrl: "https://api.getutilities.com",
  apiCheck: async () => {
    try {
      const response = await axios.get(`${Api.apiUrl}/`);
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching API:", error);
      return false;
    }
  },
  countPlans: async (): Promise<number> => {
    try {
      const response = await axios.get(`${Api.apiUrl}/data/count/plans`);
      if (response.status === 200) {
        return response.data.number;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Error fetching plan count:", error);
      return 0;
    }
  },
  countSessions: async (): Promise<number> => {
    try {
      const response = await axios.get(`${Api.apiUrl}/data/count/sessions`);
      if (response.status === 200) {
        return response.data.number;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Error fetching session count:", error);
      return 0;
    }
  },
  countRecentSessions: async (): Promise<number> => {
    try {
      const response = await axios.get(
        `${Api.apiUrl}/data/count/recentSessions`
      );
      if (response.status === 200) {
        return response.data.number;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Error fetching recent session count:", error);
      return 0;
    }
  },
  demoPlans: async (zip: string) => {
    try {
      const response = await axios.get(`${Api.apiUrl}/demo/demo`);
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching demo plans:", error);
      return false;
    }
  },
  plansByZip: async (zip: string): Promise<(pgPlanInfo | null)[]> => {
    try {
      const response = await axios.get(`${Api.apiUrl}/plans/${zip}`);
      console.log("plansByZip", response.data);
      if (response.status === 200) {
        
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching plans by zip:", error);
      return [];
    }
  },
  plansByZipPaginated: async (
    zip: string,
    offset: number,
    limit: number
  ): Promise<(pgPlanInfo | null)[]> => {
    try {
      const response = await axios.get(
        `${Api.apiUrl}/plans/p/${zip}?offset=${offset}&limit=${limit}`
      );
      console.log("plansByZipPaginated", response.data);
      if (response.status === 200) {
        
        return response.data.plans;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching plans by zip:", error);
      return [];
    }
  },
  ptcPlansByZip: async (zip: string): Promise<(pgPlanInfo | null)[]> => {
    try {
      const response = await axios.get(`${Api.apiUrl}/plans/ptc/${zip}`);
      if (response.status === 200) {
        console.log("ptcPlansByZip", response.data);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching PTC plans by zip:", error);
      return [];
    }
  },
  plansByRetailer: async (retailerId: string) => {
    try {
      const response = await axios.get(
        `${Api.apiUrl}/plans/retailer/${retailerId}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching plans by retailer:", error);
      return false;
    }
  },
  plansByMetro: async (metro: string) => {
    try {
      const response = await axios.get(`${Api.apiUrl}/plans/metro/${metro}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching plans by metro:", error);
      return false;
    }
  },
  isTxCity: async (city: string) => {
    try {
      const response = await axios.get(`${Api.apiUrl}/data/isTxCity/${city}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching isTxCity:", error);
      return false;
    }
  },
  planById: async (id: string): Promise<pgPlanInfo[] | false> => {
    try {
      const response = await axios.get(`${Api.apiUrl}/plans/id/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching plan by id:", error);
      return false;
    }
  },
  plansByIds: async (ids: string[]): Promise<pgPlanInfo[] | false> => {
    try {
      const response = await axios.post(`${Api.apiUrl}/plans/ids`, { ids });
      if (response.status === 200) {
        return response.data.map((plan: pgPlanInfo[]) => plan[0]);
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching plans by ids:", error);
      return false;
    }
  },
  findEsiId: async (searchInfo: SearchInfo) => {
    try {
      const response = await axios.post(`${Api.apiUrl}/esi/find`, {
        ...searchInfo,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching ESI ID:", error);
      return false;
    }
  },
  submitCreditCheck: async (formInfo: {
    sessionId: string;
    dataPack: string;
  }) => {
    try {
      const response = await axios.post(`${Api.apiUrl}/vbb/creditCheck`, {
        ...formInfo,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error submitting credit check");
      return false;
    }
  },
  startSession: async ({
    sessionId,
    dataPack,
  }: {
    sessionId: string;
    dataPack: string;
  }) => {
    try {
      const response = await axios.post(`${Api.apiUrl}/s/start`, {
        sessionId,
        dataPack,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error starting session:", error);
      return false;
    }
  },
  fetchSessionData: async (sessionId: string) => {
    try {
      const response = await axios.get(`${Api.apiUrl}/s/${sessionId}`);
      if (response.status === 200 || response.status === 201) {
        console.log("fetchSessionData", response.data);
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
      return false;
    }
  },
  register: async (email: string, password: string, sessionId?: string) => {
    try {
      const response = await axios.post(`${Api.apiUrl}/s/register`, {
        email,
        password,
        sessionId,
      });
      if (response.status === 201) {
        return response.data;
      }
      return false;
    } catch (error) {
      console.error("Error registering user:", error);
      return false;
    }
  },
  login: async (username: string, password: string, sessionId: string) => {
    try {
      const response = await axios.post(`${Api.apiUrl}/s/login`, {
        username,
        password,
        sessionId,
      });
      if (response.status === 200) {
        const { token, userId, role } = response.data;
        localStorage.setItem("authToken", token); // Store the JWT in local storage
        return { userId, token, role };
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error logging in:", error);
      return false;
    }
  },
  logout: async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        const response = await axios.post(`${Api.apiUrl}/s/logout`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          localStorage.removeItem("authToken"); // Remove the JWT from local storage
          return true;
        } else {
          return false;
        }
      }
      return false;
    } catch (error) {
      console.error("Error logging out:", error);
      return false;
    }
  },
  updateSessionData: async (sessionId: string, data: SessionData) => {
    try {
      if (!sessionId) {
        console.log("No sessionId provided");
        return false;
      }
      const dataPack = makeDataPack(sessionId, data);
      const response = await axios.put(
        `${Api.apiUrl}/s/${sessionId}`,
        dataPack
      );
      if (response.status === 200) {
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error updating session data:", error);
      return false;
    }
  },
  updateEnrollData: async (sessionId: string, data: EnrollData) => {
    try {
      const dataPack = makeEnrollPack(sessionId, data);
      const response = await axios.put(
        `${Api.apiUrl}/e/${sessionId}`,
        dataPack
      );
      if (response.status === 200) {
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error updating enroll data:", error);
      return false;
    }
  },
  getRetailers: async () => {
    try {
      const response = await axios.get(`${Api.apiUrl}/plans/retailers`);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching retailers:", error);
      return [];
    }
  },
  getRetailer: async (retailerId: string) => {
    try {
      const response = await axios.get(
        `${Api.apiUrl}/plans/retailer/${retailerId}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching retailer logo:", error);
      return null;
    }
  },
  getTDUs: async () => {
    try {
      const response = await axios.get(`${Api.apiUrl}/data/allTDUs`);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching TDU info:", error);
      return [];
    }
  },
  getZipInfo: async (zip: string) => {
    try {
      const response = await axios.get(`${Api.apiUrl}/data/z/info/${zip}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching zip info:", error);
      return null;
    }
  },
  enrollmentStep: async (sessionId: string, data: EnrollData) => {
    try {
      const dataPack = await makeEnrollPack(sessionId, data);
      console.log("dataPack", dataPack);
      const response = await axios.post(`${Api.apiUrl}/e/step`, dataPack);
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error submitting VBB enrollment:", error);
      return false;
    }
  },
  getGasBuddyPrices: async (zipCode: string, brandId?: number) => {
    const response = await axios.get(`${Api.apiUrl}/data/gasbuddy/${zipCode}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  },
  getNewsFeeds: async () => {
    const response = await axios.get(`${Api.apiUrl}/data/news/feeds`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  },
  getNewsForFeed: async (feedKey: string) => {
    const response = await axios.get(`${Api.apiUrl}/data/news/feeds/${feedKey}/articles`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  },
  getAllNews: async () => {
    const response = await axios.get(`${Api.apiUrl}/data/news`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  },
  addNewsFeed: async (feed: RSSFeed_T): Promise<boolean> => {
    const response = await axios.post(`${Api.apiUrl}/data/news/feeds`, {feed});
    if (response.status === 200 || response.status === 201) {
      return true;
    } else {
      return false;
    }
  },
  checkZip: async (zip: string): Promise<ZipInfo_T | null> => {
    if (!zip) {
      return null;
    }
    const response = await axios.get(`${Api.apiUrl}/data/checkZip/${zip}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  },
  getMetric: async (metric: string) => {
    const response = await axios.get(`${Api.apiUrl}/data/metric/${metric}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  },
  getZipsByMetro: async (metro: string) => {
    const response = await axios.get(`${Api.apiUrl}/plans/metroZips/${metro}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  },
  getZipsByTDU: async (tdu: string) => {
    const response = await axios.get(`${Api.apiUrl}/plans/zipsForTdu/${tdu}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  },
  submitEmail: async (email: string) => {
    const response = await axios.post(`${Api.apiUrl}/data/email`, { email });
    return response.status === 200;
  },
  getUserData: async (userId: string) => {
    try {
      const response = await axios.get(`${Api.apiUrl}/s/user/${userId}/data`);
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  },
  updateUserData: async (userId: string, data: any) => {
    try {
      const response = await axios.put(`${Api.apiUrl}/s/user/${userId}/data`, {
        dataPack: encrypt(JSON.stringify(data)),
      });
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      console.error("Error updating user data:", error);
      return false;
    }
  },
  checkToken: async (token: string) => {
    try {
      const response = await axios.post(`${Api.apiUrl}/s/check-token`, { token });
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      console.error('Error checking token:', error);
      return false;
    }
  },
  getRecentlyCreatedPlans: async (days?: number): Promise<any[]> => {
    try {
      const daysParam = days ? `?days=${days}` : '';
      const response = await axios.get(`${Api.apiUrl}/data/plans/recent/created${daysParam}`);
      console.log("getRecentlyCreatedPlans", response.status, response.data);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching recently created plans:", error);
      return [];
    }
  },
  getRecentlyDeletedPlans: async (days?: number): Promise<any[]> => {
    try {
      const daysParam = days ? `?days=${days}` : '';
      const response = await axios.get(`${Api.apiUrl}/data/plans/recent/deleted${daysParam}`);
      console.log("getRecentlyDeletedPlans", response.status, response.data);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching recently deleted plans:", error);
      return [];
    }
  }
};
export default Api;
