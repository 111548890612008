import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { UsageTiers, usePlansStore } from "@/providers/planStore";
import React, { useEffect } from "react";
const UsageTier = () => {
  const { filters, setUsageTier } = usePlansStore();
  const [selectedTier, setSelectedTier] = React.useState(
    filters.usageTier.value || UsageTiers.MEDIUM
  );

  useEffect(() => {
    setSelectedTier(filters.usageTier.value || UsageTiers.MEDIUM);
  }, [filters.usageTier.value]);

  return (
    <div className="flex flex-col sm:flex-row items-center justify-center border border-gray-300 rounded-md p-2 bg-gray-100">
      Set Usage Tier&nbsp;&nbsp;
      <div className="flex flex-row">
        {Object.values(UsageTiers).map((key: UsageTiers) => (
          <Button
            key={key}
            value={key}
            onClick={() => {
              setUsageTier({ enabled: true, value: key });
            }}
            className={cn(
              `border border-gray-300 rounded-md p-2 bg-white text-black`,
              {
                "bg-mainBlue text-white": selectedTier === key,
              }
            )}
          >
            {key}
          </Button>
        ))}
        &nbsp;kWh
      </div>
    </div>
  );
};

export default UsageTier;
