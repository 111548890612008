import React from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import Container from "@/views/Container";
const Search = (): JSX.Element => {
  return (
    <div className="container mx-auto px-4">
      <div className="mb-8 text-center">
        <h2 className="text-3xl font-bold mb-4">
          Get More. Stress Less. Power Up.
        </h2>
        <p className="text-xl text-gray-600">
          You deserve an electricity plan that works for you, whether you're
          switching to save or starting service in a new home. We make it easy.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <SearchCard
          title="Switch to a Better Deal"
          description="Is your contract expiring or already expired? Overpaying? Compare plans and switch today."
          buttonText="Find Your Best Plan"
        />
        <SearchCard
          title="Moving into a New Home?"
          description="We'll get you connected in time for your move, with the best plan for your new home - no hassle, no delays."
          buttonText="Start Electricity Service"
        />
      </div>
    </div>
  );
};

export const SearchCard = ({
  title,
  description,
  buttonText,
}: {
  title: string;
  description: string;
  buttonText: string;
}) => (
  <Card className="p-2 sm:p-8 shadow-lg gap-4 cursor-default">
    <div className="flex flex-col sm:flex-row justify-center items-center gap-2 lg:gap-4 h-full">
      <div className="lg:max-w-[60%] mb-4 sm:mb-0 flex flex-col items-center text-center md:text-left">
        <h3 className="text-xl lg:text-3xl font-bold mb-2">{title}</h3>
        <p className="text-xs lg:text-base text-gray-600">{description}</p>
      </div>
      <div className="flex flex-col justify-center items-center">
        <Button
          variant="secondary"
          size="lg"
          className="mt-4 sm:mt-0 bg-[#377DFF] text-white text-md"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  </Card>
);

export default Search;
