import { Button } from "@/components/ui/button";
import { Menu as MenuIcon } from "lucide-react";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";

import React, { useState } from "react";
import { Topbar } from "@/views/Plans/Filters/components";
import FilterSidebar from "@/views/Plans/Filters/components/Sidebar/FilterSidebar";
export interface FiltersWithSidebarProps {
  children: React.ReactNode;
  zipCode?: string;
}

const FiltersWithSidebar = (props: FiltersWithSidebarProps): JSX.Element => {
  const { children, zipCode } = props;

  const [openSidebar, setOpenSidebar] = useState(true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = openSidebar;

  return (
    <div className="flex w-full">
      <FilterSidebar />
      <div className="flex-grow sm:ml-4 w-full">
        <Topbar />
        {children}
      </div>
    </div>
  );
};

export default FiltersWithSidebar;
