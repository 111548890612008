import useAuthStore from "@/providers/authStore";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";

export interface PageHeaderProps {
  title?: string;
  col1?: React.ReactNode;
  col2?: React.ReactNode;
  col3?: React.ReactNode;
}

const PageHeader = ({ title, col1, col2, col3 }: PageHeaderProps) => {
  const { user } = useAuthStore();
  return (
    <div id="table-header" className="grid grid-cols-3 w-full max-h-[20vh] py-4">
      <div className="col-span-1 flex flex-col items-start justify-start">
        {col1}
      </div>
      <div className="col-span-1">{col2}</div>
      <div className="col-span-1">{col3}</div>
    </div>
  );
};

export default PageHeader;
