import LoginButton from "./loginButton";
import LogoutButton from "./logoutButton";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import useAuthStore from "@/providers/authStore";
import { User as UserIcon } from "lucide-react";

const UserTopBar = () => {
  const { isAuthenticated, user, loadUser } = useAuthStore();
  const navigate = useNavigate();
  return (
    <div className="flex flex-row items-center gap-4 p-4">
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
      <Button
        variant="outline"
        className="flex flex-row items-center gap-4 p-4 text-primary"
        onClick={() => navigate("/profile")}
      >
        {user?.contact?.name}
        {user?.contact?.avatar ? (
          <img
            src={user.contact.avatar}
            alt="User"
            className="w-8 h-8 rounded-full"
          />
        ) : (
          <UserIcon className="w-8 h-8" />
        )}
      </Button>
    </div>
  );
};

export default UserTopBar;
