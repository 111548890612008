import { Button } from "@/components/ui/button";
import useAuthStore from "@/providers/authStore";

import { useNavigate } from "react-router-dom";

const LoginButton: React.FC<{ username?: string; password?: string }> = ({
  username,
  password,
}) => {
  const { login } = useAuthStore();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (!username || !password) {
      navigate("/auth");
      return;
    }
    login(username, password).then(({valid, redirect}) => {
      if (!valid) {
        navigate(redirect || "/auth");
      } else {
        navigate(redirect || "/dashboard");
      }
    });
  };

  return <Button onClick={handleLogin}>Log In</Button>;
};

export default LoginButton;