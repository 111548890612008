import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import Api from "@/utils/api";
import { Metric_T } from "@/types/data";
import { cn } from "@/lib/utils";

const metricKeys = ["average-price-1000", "cheapest-plan-1000", "total-plans"];

const formatValue = (value: string | number, value_type: string) => {
  const valueString = typeof value === "number" ? value.toString() : value;
  switch (value_type) {
    case "number":
      return parseFloat(valueString).toFixed(0);
    case "percentage":
      return `${parseFloat(valueString).toFixed(2)}%`;
    case "cents":
      return `${parseFloat(valueString).toFixed(2)} ¢`;
    case "dollars":
      return `$${parseFloat(valueString).toFixed(2)}`;
    default:
      return valueString;
  }
};

const Stats: React.FC = () => {
  const [stats, setStats] = useState<Metric_T[]>([]);
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const promises = metricKeys.map(key => Api.getMetric(key));
        const results = await Promise.all(promises);
        const validResults = results.filter(res => res !== null);
        setStats(validResults);
      } catch (err) {
        console.error(err);
      }
    };

    fetchStats();
  }, []);

  return (
    <div>
      {/* <h3 className="text-base font-semibold text-gray-900">Last 30 days</h3> */}
      <dl className="my-4 grid grid-cols-1 divide-y divide-gray-200 rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0 cursor-default">
        {
          stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900 text-center">
                {item.name}
              </dt>
              <dd className={item.trend_direction ? "mt-1 flex items-center justify-between md:block lg:flex" : "mt-1 flex items-center justify-center"}>
                <div className="flex items-center text-2xl font-semibold text-mainBlue">
                  {formatValue(item.value, item.value_type)}
                  {item.value_type === "percentage" && "%"}
                  {item.trend_value && (
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {item.trend_value}
                    </span>
                  )}
                </div>
                {item.trend_direction && (
                  <div
                    className={cn(
                      item.trend_direction === "positive"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800",
                      "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                    )}
                  >
                    {item.trend_direction === "positive" ? (
                      <ArrowUpIcon
                        aria-hidden="true"
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      />
                    ) : (
                      <ArrowDownIcon
                        aria-hidden="true"
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                      />
                    )}

                    <span className="sr-only">
                      {" "}
                      {item.trend_direction === "positive"
                        ? "Increased"
                        : "Decreased"}{" "}
                      by{" "}
                    </span>
                    {item.trend_value}
                  </div>
                )}
              </dd>
            </div>
          ))}
      </dl>
    </div>
  );
};

export default Stats;
