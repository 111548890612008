import React, { useState } from 'react';
import { usePlansStore } from '@/providers/planStore';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Slider } from '@/components/ui/slider';

const valuetext = (value: number) => `${value}¢`;

const FilterPrice = (): JSX.Element => {
  const { filters, updateFilteredPlans, setFilters } = usePlansStore();
  const [value, setValue] = useState([filters.price.min, filters.price.max]);
  const [open, setOpen] = useState(true);

  const handleChange = (newValue: number[]) => {
    setValue(newValue);
    setFilters({
      ...filters,
      price: {
        ...filters.price,
        min: newValue[0],
        max: newValue[1],
        enabled: true,
      },
    });
    updateFilteredPlans();
  };

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>
        <Button variant="ghost" className="w-full justify-between">
          <span>
            Price:{' '}
            <span className="font-bold">
              {`${valuetext(value[0])} - ${valuetext(value[1])}` || ''}
            </span>
          </span>
          {open ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="px-6 py-2">
          <Slider
            min={0}
            max={99}
            step={1}
            value={value}
            onValueChange={handleChange}
            className="w-full"
          />
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default FilterPrice;
