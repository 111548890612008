import { validateZipCode } from "@/helpers/validators";
import { usePlansStore } from "@/providers/planStore";
import { useSessionStore } from "@/providers/store";
import Api from "@/utils/api";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

const Localized = () => {
  const { localeOrService } = useParams();
  const [zipInfo, setZipInfo] = useState<any>(null);
  const isZipCode = validateZipCode(localeOrService || "");
  const { fetchPlans } = usePlansStore();
  const { updateHomeInfo_zipCode } = useSessionStore();
  useEffect(() => {
    if (isZipCode) {
      Api.getZipInfo(localeOrService || "").then(setZipInfo);
      fetchPlans(localeOrService || "");
      updateHomeInfo_zipCode(localeOrService || "");
    }
  }, [localeOrService]);

  return (
    <div className="">
      <Outlet />
    </div>
  );
};

export default Localized;
