import { pgPlanInfo, PlanInfo, Source } from "@/types/plan";
import Api from "@/utils/api";
import { create } from "zustand";

export enum SortBy {
  BEST_SELLER = 'best-seller',
  BEST_MATCH = 'best-match',
  PRICE_LOW_TO_HIGH = 'price-low-to-high',
  PRICE_HIGH_TO_LOW = 'price-high-to-low',
}

export const sortByOptions = [
  {label: 'Best Seller', value: SortBy.BEST_SELLER},
  {label: 'Best Match', value: SortBy.BEST_MATCH},
  {label: 'Price Low to High', value: SortBy.PRICE_LOW_TO_HIGH},
  {label: 'Price High to Low', value: SortBy.PRICE_HIGH_TO_LOW},
];

export enum UsageTiers {
  LOW = "500",
  MEDIUM = "1000",
  HIGH = "2000",
}
export interface UsageTier {
  enabled: boolean;
  value: UsageTiers;
}

export interface Filter {
  enabled: boolean;
}

export interface minMaxFilter extends Filter {
  min: number;
  max: number;
}

export interface priceFilter extends minMaxFilter {
  basedOn: UsageTiers;
}

export interface singleValueFilter extends Filter {
  value: string;
  options?: string[];
}

export interface multiValueFilter extends Filter {
  values: string[];
  options?: string[];
}

export interface PlansFilters {
  price: priceFilter;
  contractLength: multiValueFilter;
  features: multiValueFilter;
  retailers: multiValueFilter;
  sort: singleValueFilter;
  sortDirection: singleValueFilter;
  usageTier: UsageTier;
}

export enum PlanType {
  PTC = "ptc",
  NOTPTC = "notptc",
  ALL = "all",
}
6;

export const initialState = {
  planType: PlanType.NOTPTC,

  plans: [],
  PTCPlans: [],
  filteredPlans: [],
  filteredPTCPlans: [],
  filteredPlanIds: [],
  selectedPlan: null,
  planBreadcrumbs: [],
  allFilteredPlans: [], // Added allFilteredPlans to initialState
  filters: {
    sortDirection: {
      enabled: false,
      value: "asc",
    },
    price: {
      enabled: false,
      min: 0,
      max: 99,
      basedOn: UsageTiers.MEDIUM,
    },
    features: {
      enabled: false,
      values: [],
      options: [],
    },
    contractLength: {
      enabled: false,
      values: ["12"],
    },
    retailers: {
      enabled: true,
      values: [],
      options: [],
    },
    sort: {
      enabled: true,
      value: SortBy.PRICE_LOW_TO_HIGH,
    },
    usageTier: {
      enabled: true,
      value: UsageTiers.MEDIUM,
    },
  },
};

interface PlansState {
  planType: PlanType;
  plans: pgPlanInfo[];
  PTCPlans: pgPlanInfo[];
  filteredPlans: pgPlanInfo[];
  filteredPTCPlans: pgPlanInfo[];
  filteredPlanIds: string[];
  selectedPlan: PlanInfo | null;
  planBreadcrumbs: { id: string; name: string; href: string }[];
  allFilteredPlans: pgPlanInfo[]; // Added allFilteredPlans to PlansState
  filters: PlansFilters;
  setPlans: (plans: pgPlanInfo[]) => void;
  setPTCPlans: (plans: pgPlanInfo[]) => void;
  setFilteredPlans: (filteredPlans: pgPlanInfo[]) => void;
  setUsageTier: (usageTier: UsageTier) => void;
  setSortBy: (sortBy: SortBy) => void;
  getAllPlans: () => pgPlanInfo[];
  getAllFilteredPlans: () => pgPlanInfo[];
  setFilteredPTCPlans: (filteredPTCPlans: pgPlanInfo[]) => void;
  setFilteredPlanIds: (filteredPlanIds: string[]) => void;
  setSelectedPlan: (selectedPlan: PlanInfo | null) => void;
  setPlanBreadcrumbs: (
    planBreadcrumbs: { id: string; name: string; href: string }[]
  ) => void;
  setFilters: (filters: PlansFilters) => void;
  addFilter: (key: string, value: any) => void;
  removeFilter: (key: string) => void;
  clearFilters: () => void;
  updateFilteredPlans: () => void;
  updateFilteredPTCPlans: () => void;
  setContractLengthOptions: (options: string[]) => void;
  setRetailerOptions: (options: string[]) => void;
  fetchPlans: (zipCode: string) => void;
  fetchPTCPlans: (zipCode: string) => void;
  fetchAllPlans: (zipCode: string) => void;
}

export const filterEnglishOnly = (plans: pgPlanInfo[]): pgPlanInfo[] => {
  return plans.filter(
    (plan) =>
      !plan.data.language || plan.data.language.toLowerCase() === "english"
  );
};

export const sortBySource = (plans: pgPlanInfo[]): pgPlanInfo[] => {
  return plans.sort((a, b) => {
    if (a.data.info.source === Source.PowerToChoose) {
      return 1;
    } else if (b.data.info.source === Source.PowerToChoose) {
      return -1;
    }
    return 0;
  });
};

export const usePlansStore = create<PlansState>((set, get) => ({
  ...initialState,
  setPlans: (plans) => {
    if (!plans || !plans.length) {
      return;
    }
    //Sort the plans by the current sort value
    switch (get().filters.sort.value) {
      case SortBy.BEST_SELLER:
        //TODO: Implement best seller sorting
        plans = filterEnglishOnly(plans);
        plans = plans.sort(() => Math.random() - 0.5);
        break;
      case SortBy.BEST_MATCH:
        //TODO: Implement best match sorting
        plans = filterEnglishOnly(plans);
        plans = plans.sort(() => Math.random() - 0.5);
        break;
      case SortBy.PRICE_LOW_TO_HIGH:
        plans = filterEnglishOnly(plans);
        plans = plans.sort(
          (a, b) =>
            (a?.data?.["plan-pricing"]["kwh-1000"] || 0) -
            (b?.data?.["plan-pricing"]["kwh-1000"] || 0)
        );
        break;
      case SortBy.PRICE_HIGH_TO_LOW:
        plans = filterEnglishOnly(plans);
        plans = plans.sort(
          (a, b) =>
            (b?.data?.["plan-pricing"]["kwh-1000"] || 0) -
            (a?.data?.["plan-pricing"]["kwh-1000"] || 0)
        );
        break;
    }
    set({ plans: plans as pgPlanInfo[] });
    get().updateFilteredPlans();
    if (plans.length > 0) {
      const uniqueContractLengths = new Set(
        plans.map((plan) => plan?.data?.["term-length"])
      );
      set((state) => ({
        filters: {
          ...state.filters,
          contractLength: {
            ...state.filters.contractLength,
            options: Array.from(uniqueContractLengths)
              .map(String)
              .sort((a, b) => parseInt(a) - parseInt(b)),
          },
        },
      }));
      const uniqueRetailers = new Set(
        plans.map((plan) => plan?.data?.retailer?.name).filter(Boolean)
      );
      set((state) => ({
        filters: {
          ...state.filters,
          retailers: {
            ...state.filters.retailers,
            options: Array.from(uniqueRetailers) as string[],
          },
        },
      }));
    }
  },
  setUsageTier: (usageTier) => {
    get().setFilters({ ...get().filters, usageTier });
    get().updateFilteredPlans();
  },
  setSortBy: (sortBy: SortBy) => {
    get().setFilters({
      ...get().filters,
      sort: { ...get().filters.sort, value: sortBy },
    });
    get().updateFilteredPlans();
  },
  setPTCPlans: (plans) => {
    if (!plans || !plans.length) {
      return;
    }
    set({ PTCPlans: plans as pgPlanInfo[] });
    get().updateFilteredPTCPlans();
  },
  setFilteredPlans: (filteredPlans) => set({ filteredPlans }),
  setFilteredPTCPlans: (filteredPTCPlans) => set({ filteredPTCPlans }), // Added setFilteredPTCPlans
  getAllPlans: () => [...get().plans, ...get().PTCPlans],
  getAllFilteredPlans: () =>
    sortPlans(
      applyFilters([...get().plans, ...get().PTCPlans], get().filters),
      get().filters
    ),
  setFilteredPlanIds: (filteredPlanIds) => set({ filteredPlanIds }),
  setSelectedPlan: (selectedPlan) => set({ selectedPlan }),
  setPlanBreadcrumbs: (planBreadcrumbs) => set({ planBreadcrumbs }),
  setFilters: (filters) => set({ filters }),
  addFilter: (key, value) =>
    set((state) => ({ filters: { ...state.filters, [key]: value } })),
  removeFilter: (key) =>
    set((state) => ({ filters: { ...state.filters, [key]: undefined } })),
  clearFilters: () => set((state) => ({ filters: initialState.filters })),
  updateFilteredPlans: () => {
    const state = get();
    const filteredPlans = applyFilters(state.plans, state.filters);
    const sortedFilteredPlans = sortPlans(filteredPlans, state.filters);
    set({ filteredPlans: sortedFilteredPlans });
  },
  updateFilteredPTCPlans: () => {
    const state = get();
    const filteredPTCPlans = applyFilters(state.PTCPlans, state.filters);
    const sortedFilteredPTCPlans = sortPlans(filteredPTCPlans, state.filters);
    set({ filteredPTCPlans: sortedFilteredPTCPlans });
  },
  setContractLengthOptions: (options) =>
    set((state) => ({
      filters: {
        ...state.filters,
        contractLength: { ...state.filters.contractLength, options },
      },
    })),
  setRetailerOptions: (options) =>
    set((state) => ({
      filters: {
        ...state.filters,
        retailers: { ...state.filters.retailers, options },
      },
    })),
  fetchPlans: async (zipCode: string) => {
    let plans = await Api.plansByZipPaginated(zipCode, 0, 100);
    if (!plans) {
      return;
    }
    let notNullPlans = plans.filter(
      (plan): plan is pgPlanInfo => plan !== null
    );
    notNullPlans = notNullPlans.map((plan) => {
      if (plan && !plan?.data?.["term-length"]) {
        const planName = plan?.data?.["info"]["plan-name"];
        // Extract only numbers from the plan name using regex
        const termLength = planName?.match(/\d+/)?.[0];
        if (termLength) {
          plan.data["term-length"] = parseInt(termLength, 10);
        }
        return plan;
      }
      return plan;
    });
    get().setPlans(notNullPlans as pgPlanInfo[]);
    if (plans.length === 100) {
      let offset = 100;
      while (true) {
        const morePlans = await Api.plansByZipPaginated(zipCode, offset, 100);
        const filteredMorePlans = morePlans.filter(
          (plan): plan is pgPlanInfo => plan !== null
        );
        let newPlans = plans
          .concat(filteredMorePlans)
          .filter((plan): plan is pgPlanInfo => plan !== null);
        get().setPlans(newPlans);
        plans = newPlans;
        offset += 100;
      }
    }
    const ptcPlans = await Api.ptcPlansByZip(zipCode);
    get().setPTCPlans(ptcPlans as pgPlanInfo[]);
  },
  fetchPTCPlans: async (zipCode: string) => {
    const response = await Api.ptcPlansByZip(zipCode);
    get().setPTCPlans(response as pgPlanInfo[]);
  },
  fetchAllPlans: async (zipCode: string) => {
    await get().fetchPlans(zipCode);
    const response = await Api.ptcPlansByZip(zipCode);
    get().setPTCPlans(response as pgPlanInfo[]);
  },
}));

const applyFilters = (
  plans: pgPlanInfo[],
  filters: PlansFilters
): pgPlanInfo[] => {
  plans = plans.filter(
    (plan) => plan.data.language?.toLowerCase() !== "spanish"
  );
  return plans.filter((plan) => {
    const planInfo: PlanInfo = plan.data;
    filters.price.basedOn = filters.usageTier.value;
    if (filters.price.enabled) {
      const priceKey = `kwh-${filters.price.basedOn}`;
      const minPrice = filters.price.min;
      const maxPrice = filters.price.max;
      const price = planInfo["plan-pricing"][
        priceKey as keyof (typeof planInfo)["plan-pricing"]
      ] as number;
      if (price < minPrice || price > maxPrice) {
        return false;
      }
    }

    if (
      filters.contractLength.enabled &&
      filters.contractLength.values.length > 0
    ) {
      if (
        !filters.contractLength.values.includes(
          planInfo["term-length"].toString()
        )
      ) {
        return false;
      }
    }

    if (filters.retailers.enabled && filters.retailers.values.length > 0) {
      if (!filters.retailers.values.includes(planInfo.retailer.name)) {
        return false;
      }
    }

    return true;
  });
};

const sortPlans = (
  filteredPlans: pgPlanInfo[],
  filters: PlansFilters
): pgPlanInfo[] => {
  const { sort, sortDirection, usageTier } = filters;
  const direction = sortDirection.value === "asc" ? 1 : -1;
  filteredPlans = filteredPlans.filter(
    (plan) => plan.data.language?.toLowerCase() !== "spanish"
  );
  const lowestPricePlans = findLowestPricePlans(filteredPlans, usageTier.value);
  // Get all plans that are not the lowest price plans
  let notLowestPricePlans = filteredPlans.filter(
    (plan) => !lowestPricePlans.includes(plan)
  );
  // Remove the lowest price tag from the not lowest price plans
  notLowestPricePlans = notLowestPricePlans.map((plan) => {
    plan.data.features.tags = plan.data.features?.tags?.filter(
      (tag) => tag !== "lowest-price"
    );
    return plan;
  });
  // Add the lowest price tag to the lowest price plans
  lowestPricePlans.forEach((plan) => {
    if (!plan.data.features.tags) {
      plan.data.features.tags = ["lowest-price"];
    } else {
      if (!plan.data.features.tags.includes("lowest-price")) {
        plan.data.features.tags.push("lowest-price");
      }
    }
  });
  // Combine the lowest price plans and the not lowest price plans
  const allPlans = [...lowestPricePlans, ...notLowestPricePlans];
  // Sort the combined plans by the current sort value
  return [...allPlans].sort((a, b) => {
    const planA = a.data;
    const planB = b.data;
    const priceKey = `kwh-${usageTier.value}`;
    switch (sort.value) {
      case SortBy.PRICE_LOW_TO_HIGH:
        return (
          (Number(
            planA["plan-pricing"][
              priceKey as keyof (typeof planA)["plan-pricing"]
            ]
          ) -
            Number(
              planB["plan-pricing"][
                priceKey as keyof (typeof planB)["plan-pricing"]
              ]
            )) *
          direction
        );
      case SortBy.PRICE_HIGH_TO_LOW:
        return (
          (Number(
            planB["plan-pricing"][
              priceKey as keyof (typeof planB)["plan-pricing"]
            ]
          ) -
            Number(
              planA["plan-pricing"][
                priceKey as keyof (typeof planA)["plan-pricing"]
              ]
            )) *
          direction
        );
      case SortBy.BEST_SELLER:
        // Temporarily sort by price
        return (
          (Number(
            planA["plan-pricing"][
              priceKey as keyof (typeof planA)["plan-pricing"]
            ]
          ) -
            Number(
              planB["plan-pricing"][
                priceKey as keyof (typeof planB)["plan-pricing"]
              ]
            )) *
          direction
        );
      case SortBy.BEST_MATCH:
        // Temporarily sort by price
        return (
          (Number(
            planA["plan-pricing"][
              priceKey as keyof (typeof planA)["plan-pricing"]
            ]
          ) -
            Number(
              planB["plan-pricing"][
                priceKey as keyof (typeof planB)["plan-pricing"]
              ]
            )) *
          direction
        );
      default:
        return 0;
    }
  });
};

const findLowestPricePlans = (plans: pgPlanInfo[], usageTier: UsageTiers): pgPlanInfo[] => {
  if (plans.length === 0) return [];

  let minPrice = Infinity;
  let lowestPricePlans: pgPlanInfo[] = [];

  plans.forEach((plan) => {
    const price = plan.data["plan-pricing"][`kwh-${usageTier}`];
    if (price < minPrice) {
      minPrice = price;
      lowestPricePlans = [plan];
    } else if (price === minPrice) {
      lowestPricePlans.push(plan);
    }
  });

  return lowestPricePlans;
};