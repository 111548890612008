import { useState } from "react";
import MainTopBar from "./mainTopBar";
import MainFooter from "./mainFooter";
import { Outlet } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner";

const MainLayout: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col pt-16">
      <MainTopBar />
      <main className="flex-1">
        <Outlet />
      </main>
      <MainFooter />
    </div>
  );
};

export default MainLayout;
