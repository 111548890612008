import Page from "@/components/Page";
import StateHeader from "./components/StateHeader";
import StateDeregulationInfo from "./components/StateInfo";
import { Helmet } from "react-helmet";
const StateWrapper = ({
  children,
  state,
}: {
  children: React.ReactNode;
  state: string;
}) => {
  state = state.toUpperCase();
  return (
    <Page>
      <Helmet>
        <title>{`Electricity Plans in ${state} | Get Electricity`}</title>
        <meta 
          name="description" 
          content={`Compare electricity rates and plans in ${state}. Find the best electricity providers and prices in your area.`} 
        />
        <meta 
          name="keywords" 
          content={`${state} electricity, ${state} power plans, electricity rates ${state}, energy providers ${state}, compare electricity rates`} 
        />
        {/* Open Graph tags for social sharing */}
        <meta 
          property="og:title" 
          content={`Electricity Plans in ${state} | Get Electricity`} 
        />
        <meta 
          property="og:description" 
          content={`Compare electricity rates and plans in ${state}. Find the best electricity providers and prices in your area.`} 
        />
        <meta 
          property="og:url" 
          content={`https://getelectricity.com/${state.toLowerCase()}`} 
        />
        {/* Canonical URL */}
        <link 
          rel="canonical" 
          href={`https://getelectricity.com/${state.toLowerCase()}`} 
        />
      </Helmet>
      <StateHeader state={state} />
      <StateDeregulationInfo state={state} />
      {children}
    </Page>
  );
};

export default StateWrapper;
