import React from 'react';
import { useTheme } from '@/theme/ThemeContext';
import { cn } from '@/lib/utils';

interface Props {
  children: React.ReactNode;
}

export default function Page({ children }: Props): JSX.Element {
  const { mode } = useTheme();

  return (
    <div 
      className={cn(
        "min-h-screen max-w-[900px] lg:max-w-[1200px] mx-auto",
        "bg-background text-foreground",
        `app ${mode}`
      )}
    >
      {children}
    </div>
  );
}
