const Container: React.FC<{ children: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  return (
    <div className="flex flex-col mx-auto items-center justify-center p-4">
      {children}
    </div>
  );
};

export default Container;
