import { Helmet } from "react-helmet";
import IconScroll from "@/components/individual/IconScroll/IconScroll";
import { AskExpert, GetStarted, Hero2, Search, Teaser } from "./components";
import Services from "@/views/Landing/components/Services/Services";
import Container from "@/views/Container";
import Stats from "./components/Stats/Stats";
const Landing = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>Homepage - GetElectricity</title>
        <meta
          name="description"
          content="Find the best electric plans in Texas. Shop for plans and educate yourself about electricity options."
        />
        <meta
          name="keywords"
          content="electricity, Texas, electric plans, energy, power, utility"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Hero2 />
      <section aria-labelledby="stats-section" className="container">
        <Stats />
      </section>
      <section aria-labelledby="search-section" className="container">
        <Search />
      </section>
      <section aria-labelledby="services-section" className="container cursor-default">
        <Services />
      </section>
      <section aria-labelledby="teaser-section" className="container cursor-default">
        <Teaser />
      </section>
      <section aria-labelledby="partners-section" className="mt-4 cursor-default">
        <div className="my-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#377DFF] text-center my-6">
            TRUSTED PROVIDERS YOU CAN COUNT ON
          </h2>
          <IconScroll />
        </div>
      </section>
      <section aria-labelledby="ask-expert-section" className="container cursor-default">
        <AskExpert />
      </section>
      <section aria-labelledby="search-section" className="container cursor-default">
        <div className="my-12">
          <GetStarted />
        </div>
      </section>
    </>
  );
};

export default Landing;
