import { RouterProvider } from "react-router-dom";
import Page from "./components/Page";
import { router } from "./Routes";

const App = (): JSX.Element => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
