import FeatureChip from "@/components/individual/featureChip";
import Page from "@/components/Page";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { stripAlphanumeric, toTitleCase } from "@/helpers/genericHelpers";
import { cn } from "@/lib/utils";
import { usePlansStore } from "@/providers/planStore";
import { useRetailerStore } from "@/providers/retailerStore";
import { useSessionStore } from "@/providers/store";
import { pgPlanInfo, PlanInfo } from "@/types/plan";
import FiltersWithSidebar from "@/views/Plans/Filters/FiltersWithSidebar";
import PlanCards from "@/views/Plans/PlansGrid/PlanCards";
import ProductDetails from "@/views/Plans/ProductDetails/ProductDetails";
import React, { useState } from "react";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const CustomChip = ({
  label,
  color = "default",
  className,
}: {
  label: string;
  color?: "default" | "primary" | "success" | "error" | string;
  className?: string;
}) => {
  const baseClasses =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";
  const colorClasses = {
    default: "bg-gray-100 text-gray-800",
    primary: "bg-blue-100 text-blue-800",
    success: "bg-green-100 text-green-800",
    error: "bg-red-100 text-red-800",
  };
  const chipColors = Object.keys(colorClasses).includes(color)
    ? colorClasses[color as keyof typeof colorClasses]
    : `bg-${color}-100 text-${color}-800`;
  const classNames = className
    ? cn(`${baseClasses} ${chipColors} ${className}`)
    : cn(`${baseClasses} ${chipColors}`);
  return <div className={classNames}>{label}</div>;
};

const formatDescription = (description: string) => {
  let splits = description.split(".");
  splits = splits.filter((split) => split.trim() !== "");
  splits = splits.map((split) => stripAlphanumeric(split.trim()));
  let bulletPoints = splits.map((split, index) => {
    return (
      <li key={index} style={{ listStyleType: "disc" }}>
        {split}
      </li>
    );
  });
  return <ul>{bulletPoints}</ul>;
};

export const renderChips = (plan: PlanInfo) => {
  const features = Object.keys(plan.features);
  return features.map((feature) => {
    if (plan.features[feature as keyof PlanInfo["features"]]) {
      if (feature === "tags") {
        return plan.features.tags?.map((tag, i) => {
          let newTag = tag.replace("-", " ");
          newTag = toTitleCase(newTag);
          if (tag === "lowest-price") {
            return (
              <FeatureChip key={i} feature="lowest-price" variant="secondary">
                {newTag}
              </FeatureChip>
            );
          }
          return (
            <FeatureChip key={i} feature="tags" variant="secondary">
              {newTag}
            </FeatureChip>
          );
        });
      }
      return (
        <FeatureChip
          key={feature}
          feature={feature as keyof PlanInfo["features"]}
        />
      );
    }
    return null;
  });
};

const termLengthChip = (termLength: number) => {
  const termLengthNumberString = termLength.toString().match(/\d+/)?.[0];
  if (!termLengthNumberString) {
    return null;
  }
  const termLengthNumber = parseInt(termLengthNumberString, 10);
  if (termLengthNumber > 1 && termLengthNumber < 124) {
    return <CustomChip label={`${termLength} Months`} color="primary" />;
  }
  return null;
};

const ratingChip = (rating: number) => {
  if (rating > 0 && rating < 5) {
    const stars = "★".repeat(rating) + "☆".repeat(5 - rating);
    return <CustomChip label={stars} />;
  }
  return null;
};

const PlansView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const enrollmentPath =
    location.pathname.split("/").slice(0, -1).join("/") + "/enrollment";
  const { zipCode: urlZipCode } = useParams<{ zipCode: string }>();
  const loaderZipCode = useLoaderData() as string;
  const planStore = usePlansStore();
  const retailerStore = useRetailerStore();
  const { updateFilterHistory, homeinfo_zipCode, updateHomeInfo_zipCode } =
    useSessionStore();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [displayPlans, setDisplayPlans] = useState<pgPlanInfo[]>(
    planStore.filteredPlans
  );
  const [numPlansToDisplay, setNumPlansToDisplay] = useState(50);

  const zipCode = urlZipCode || loaderZipCode || homeinfo_zipCode;

  const handlePlanClick = (plan: PlanInfo) => {
    planStore.setSelectedPlan(plan);
  };

  const showMorePlans = () => {
    setNumPlansToDisplay(numPlansToDisplay + 50);
  };

  React.useEffect(() => {
    if (zipCode) {
      updateHomeInfo_zipCode(zipCode);
      planStore.fetchPlans(zipCode);
    }
  }, [zipCode, updateHomeInfo_zipCode]);

  React.useEffect(() => {
    setDisplayPlans(planStore.filteredPlans);
  }, [planStore.filteredPlans]);

  return (
    <Page>
      <FiltersWithSidebar zipCode={zipCode}>
        <ProductDetails
          onClose={() => {
            setDetailsOpen(false);
            planStore.setSelectedPlan(null);
          }}
          open={detailsOpen}
          plan={planStore.selectedPlan}
        />
        <div className="mt-4 w-full flex flex-col items-center min-w-full sm:min-w-[40vw]">
          {displayPlans.slice(0, numPlansToDisplay).map((plan, i) => (
            <div
              key={i}
              className="w-full mb-4 transition-all duration-200 ease-in-out hover:md:-translate-y-1 p-1"
            >
              <Card className="w-full flex flex-col border-2 border-gray-200 shadow-md">
                <div className="w-full flex flex-row justify-between px-2">
                  <CardHeader className="cursor-default">
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
                      <h3 className="text-lg md:text-xl font-bold text-mainBlue">
                        {plan.data?.info?.["plan-name"]}
                      </h3>
                      <p className="text-sm md:text-base text-gray-600">
                        {plan.data?.retailer?.name}
                      </p>

                      <div className="px-4 flex flex-wrap gap-2 cursor-default">
                        {!!plan.data?.["term-length"] &&
                          termLengthChip(plan.data?.["term-length"])}
                        {!!plan.data?.["rating"] &&
                          ratingChip(parseInt(plan.data?.["rating"]))}
                        {renderChips(plan.data).map((chip, i) => (
                          <React.Fragment key={i}>{chip}</React.Fragment>
                        ))}
                      </div>
                    </div>
                  </CardHeader>

                  <img
                    src={plan.data?.retailer?.logo || ""}
                    alt={plan.data?.retailer?.name}
                    className="w-24 h-24 md:w-30 md:h-30 object-contain md:mr-4 mt-2 mb-2 md:mt-0 md:mb-0"
                  />
                </div>
                <CardContent className="p-4">
                  <div className="flex flex-col md:flex-row md:justify-between">
                    <div className="md:max-w-[50%] p-4 cursor-default">
                      {formatDescription(
                        plan.data.info["plan-description"] || ""
                      )}
                    </div>
                    <div className="h-[150px] md:h-[200px] overflow-hidden mt-2 md:mt-0 cursor-default">
                      <PlanCards plan={plan.data} />
                    </div>
                  </div>
                </CardContent>
                <CardFooter className="flex flex-col-reverse sm:flex-row justify-between p-4 gap-2">
                  <Button
                    variant="outline"
                    className="w-full md:w-auto"
                    onClick={() => {
                      setDetailsOpen(true);
                      handlePlanClick(plan.data);
                    }}
                  >
                    Learn more
                    <svg
                      className="w-4 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </Button>
                  <Button
                    variant="default"
                    className="w-full md:w-auto bg-mainBlue text-white"
                    onClick={() => {
                      setDetailsOpen(false);
                      planStore.setSelectedPlan(plan.data);
                      navigate(enrollmentPath);
                    }}
                  >
                    Sign Up
                    <svg
                      className="w-4 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </Button>
                </CardFooter>
              </Card>
            </div>
          ))}
          {numPlansToDisplay < planStore.filteredPlans.length && (
            <Button variant="outline" className="mt-4" onClick={showMorePlans}>
              Show More
            </Button>
          )}
        </div>
      </FiltersWithSidebar>
    </Page>
  );
};

export default PlansView;
