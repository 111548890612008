import React, { useState } from 'react';
import { usePlansStore } from '@/providers/planStore';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';

const FilterLength = (): JSX.Element => {
  const { filters, updateFilteredPlans } = usePlansStore();
  const [open, setOpen] = useState(true);

  const handleLengthClick = (item: string) => {
    const currentValues = filters.contractLength.values;
    let newValues: string[];

    if (currentValues.includes(item)) {
      newValues = currentValues.filter((value) => value !== item);
    } else {
      newValues = [...currentValues, item];
    }

    updateFilteredPlans();
  };

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>
        <Button variant="ghost" className="w-full justify-between">
          <span className="font-bold">Term Length:</span>
          {open ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-row flex-wrap gap-2 mt-2">
          {filters.contractLength.options?.map((item) => (
            <Button
              key={item}
              onClick={() => handleLengthClick(item)}
              variant={filters.contractLength.values.includes(item) ? "default" : "outline"}
              size="sm"
            >
              {item}
            </Button>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default FilterLength;
