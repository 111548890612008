import { MessageCircle as MessageIcon, Phone as PhoneIcon } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const mock = [
  {
    icon: <PhoneIcon className="w-6 h-6" />,
    title: "Schedule a call",
    subtitle:
      "Schedule a call with one of our experts to help you find the best plan for your home.",
    button: "Schedule a call",
  },
  {
    icon: <MessageIcon className="w-6 h-6" />,
    title: "Start a chat",
    subtitle:
      "Start a chat with one of our experts to help you find the best plan for your home.",
    button: "Start a chat",
  },
];

const AskExpert = (): JSX.Element => {
  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-1/2">
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-[#377DFF] mb-2">
              Ask an expert
            </h2>
            <p className="text-lg text-gray-600">
              Connect with one of our experts to help you find the best
              electricity plan for your home.
              <br />
              Communicate the way you want.
              <br />
              We'll help you every step of the way.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row gap-6">
            {mock.map((item, index) => (
              <Card key={index} className="flex-1">
                <CardContent className="p-6 flex flex-col justify-between h-full">
                  <div className="flex flex-col items-start justify-between h-full">
                    <div>
                      <div className="bg-primary/10 text-[#377DFF] p-3 rounded-lg mb-4 shadow-inner h-12 w-12 flex items-center justify-center">
                        {item.icon}
                      </div>
                      <h3 className="text-xl font-semibold mb-2">
                        {item.title}
                      </h3>
                      <p className="text-gray-600 mb-4">{item.subtitle}</p>
                    </div>
                    <Button
                      variant="secondary"
                      className="bg-[#377DFF] text-white text-md"
                    >
                      {item.button}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
        <div className="hidden md:block w-full md:w-1/2">
          <Card className="w-full h-full">
            <img
              src="https://storage.googleapis.com/getelectricity-assets/images/customer-service.jpg"
              alt="Customer Service"
              className="w-full h-full object-cover min-h-[300px]"
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AskExpert;
