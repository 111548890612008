import * as React from "react";
import {
  AudioWaveform,
  BookOpen,
  Bot,
  Command,
  Frame,
  GalleryVerticalEnd,
  LifeBuoy,
  Map,
  PieChart,
  Send,
  Settings2,
  Zap,
  SquareTerminal,
} from "lucide-react";
import { panels } from "@/views-auth/routes";
import { TeamSwitcher } from "./team-switcher";
import { iconRouteObject } from "@/views-auth/routes";
import { Link } from "react-router-dom";
import { NavMain } from "./nav-main";
import { NavProjects } from "./nav-projects";
import { NavSecondary } from "./nav-secondary";
import { NavUser } from "./nav-user";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";

const data: {
  user: any;
  teams: any;
} = {
  user: {
    name: "Andrew R",
    email: "Andrew@andrewrentschler.com",
    avatar: "/avatars/shadcn.jpg",
  },
  
  teams: [
    {
      name: "GetElectricity",
      logo: Zap,
      plan: "",
    },
    {
      name: "GetUtilities",
      logo: AudioWaveform,
      plan: "",
    },
    {
      name: "XYZ",
      logo: Command,
      plan: "",
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar variant="inset" collapsible="icon" {...props} className="h-full bg-stone-100">
      <SidebarHeader className="bg-stone-100">
        <TeamSwitcher teams={data.teams} />
      </SidebarHeader>
      <SidebarContent className="flex flex-col gap-2 text-lg bg-stone-100">
        <NavMain items={panels} />
      </SidebarContent>
      <SidebarFooter>

        <NavUser user={data.user} />
      </SidebarFooter>
    </Sidebar>
  );
}
