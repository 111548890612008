import { Input } from "@/components/ui/input";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import useAuthStore from "@/providers/authStore";
import { useNavigate } from "react-router-dom";

const LoginWithCode: React.FC<{
  setDisplay: (display: "login" | "register" | "code") => void;
}> = ({ setDisplay }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const { verifyCode } = useAuthStore();
  const navigate = useNavigate();

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9-]/g, ""); // Allow only alphanumeric and dashes
    if (value.length >= 0 && value.length <= 48) {
      setCode(value); // Convert to uppercase for consistency
      setError("");
    }
    if (value.length > 48) {
      setError("Code is too long");
    }
  };

  const handleVerifyCode = () => {
    console.log("HANDLE VERIFY CODE");
    const { valid, redirect } = verifyCode(code);
    if (valid && !!redirect) {
      navigate(redirect);
    }
  };

  return (
    <Card className="flex flex-col gap-4 items-center justify-around m-auto min-w-[600px] min-h-[600px] shadow-lg text-primary bg-background">
      <CardHeader>
        <CardTitle className="text-center text-3xl font-bold text-primary">
          You got one of those fancy codes huh?
        </CardTitle>
        <CardDescription className="text-center text-sm text-primary">
          You must be a big shot then! Enter that code here and we'll get you
          in.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-4 items-center justify-center w-full text-primary">
        <div className="flex flex-col gap-2 items-center justify-center w-[60%]">
          <Input
            type="text"
            placeholder="code"
            value={code}
            onChange={handleCodeChange}
            maxLength={48}
            className="text-center text-primary"
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
        <Button
          disabled={code.length < 1}
          className="text-primary"
          onClick={handleVerifyCode}
        >
          Validate
        </Button>
      </CardContent>
      <CardFooter>
        {" "}
        <Button
          variant="link"
          onClick={() => setDisplay("login")}
          className="text-primary"
        >
          Go Back
        </Button>
      </CardFooter>
    </Card>
  );
};

export default LoginWithCode;
