import { RouteParams } from "@/Routes";
import {
  Activity,
  PackageSearch,
  PlugZap,
  UtilityPole,
  TextSelect,
  Database,
  Home,
  Map,
  Users,
  Settings,
  Newspaper,
  Calculator as CalculatorIcon,
} from "lucide-react";
import { RouteObject } from "node_modules/@sentry/react/build/types/types";
import Dashboard from "./Dashboard/Frames/Dashboard";
import LeftNav from "./Dashboard/Frames/LeftNav";
import LayoutSidebar from "./Dashboard/Frames/Sidebar/layout-sidebar";
import DataPanel from "./Dashboard/Panels/tasks/DataPanel";
import BigChart from "./Dashboard/Panels/BigChart";
import TDUPanel from "./Dashboard/Panels/tdus/tduPanel";
import { MapPanel } from "./Dashboard/Panels/map/mapPanel";
import PlansPaneTable from "./Dashboard/Panels/plans/plansPaneTable";
import Calculators from "./Dashboard/Panels/calculators/calculators";
import { HomePanel } from "./Dashboard/Panels/home/homePanel";
import { Plans2 } from "./Dashboard/Panels/plans2/plans2";
import { NewsPanel } from "./Dashboard/Panels/news/newsPanel";
export interface iconRouteObject extends RouteParams {
  icon: React.ElementType;
}

export const panels: iconRouteObject[] = [
  {
    path: "",
    label: "Home",
    element: <HomePanel />,
    icon: Home,
  },
  {
    path: "retailers",
    label: "Retailers",
    element: <DataPanel />,
    icon: PlugZap,
  },
  {
    path: "tdus",
    label: "TDUs",
    element: <TDUPanel />,
    icon: UtilityPole,
  },
  {
    path: "plans",
    label: "Plans",
    element: <Plans2 />,
    icon: TextSelect,
  },
  {
    path: "map",
    label: "Map", 
    element: <MapPanel />,
    icon: Map,
  },
  {
    path: "calculators",
    label: "Calculators",
    element: <Calculators />,
    icon: CalculatorIcon,
  },
  {
    path: "news",
    label: "News",
    element: <NewsPanel />,
    icon: Newspaper,
  },
];

export const bottomPanels: iconRouteObject[] = [
  {
    path: "bigchart",
    label: "Big Chart",
    element: <BigChart />,
    icon: Activity,
  },
];

export const protectedRoutes: RouteObject[] = [
  {
    path: "/dashboard",
    element: <LayoutSidebar />,
    children: panels.concat(bottomPanels).map(({ path, element }) => ({
      path,
      element,
    })),
  },
];
