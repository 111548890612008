import { useState, useEffect } from "react";
// @material-tailwind/react
import Chart from "react-apexcharts";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { usePlansStore } from "@/providers/planStore";
import { pgPlanInfo } from "@/types/plan";
import { calculatePrice } from "@/views/Plans/ProductDialog/ProductDialog";
const BigChart = () => {
  const [zipCode, setZipCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchAllPlans, fetchPTCPlans, getAllPlans, plans, PTCPlans } = usePlansStore();
  // Calculate data for the second line (average rate)
  const usage = [250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000];
  const totalBillData = (plan: pgPlanInfo) => {
    return {
        name: plan.data["info"]["plan-name"],
        data: usage.map((usage) => parseFloat(calculatePrice(usage, plan.data)?.totalBill || '0'))
    }
  }
  const seriesData = PTCPlans.map(totalBillData);
  return (
    <div className="h-full w-full">
      <Input value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
      <Button onClick={() => fetchPTCPlans(zipCode)}>Fetch All Plans</Button>
      <TheChart seriesData={seriesData} usage={usage} />
      <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowY: 'auto', padding: '10px', backgroundColor: '#f5f5f5', border: '1px solid #ddd', borderRadius: '4px' }}>
        {JSON.stringify([...plans, ...PTCPlans], null, 2)}
      </pre>
    </div>
  );
};

const TheChart: React.FC<{ seriesData: {name: string, data: number[]}[], usage: number[] }> = ({ seriesData, usage }) => {
  const isMobile = false;

  const chartConfig = {
    type: "line" as const,
    height: isMobile ? 400 : 1000,
    series: seriesData,
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      zoom: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#020617", "#3B82F6"],
      stroke: {
        curve: "straight" as const,
        width: [3, 3],
      },
      legend: {
        show: false,
        position: "top" as const,
        fontSize: isMobile ? "10px" : "12px",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: isMobile ? "10px" : "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
          formatter: (value: string) => {
            // Convert string to number if needed, then format
            const numValue = parseFloat(value);
            return isNaN(numValue) ? value : numValue.toFixed(0);
          },
        },
        title: {
          text: "Usage (kWh)",
          style: {
            fontSize: isMobile ? "10px" : "12px",
          },
        },
        categories: usage,
      },
      yaxis: [
        {
          title: {
            text: "Total Bill ($)",
            style: {
              fontSize: isMobile ? "10px" : "12px",
            },
          },
          labels: {
            style: {
              colors: "#3B82F6",
              fontSize: isMobile ? "10px" : "12px",
            },
            formatter: (value: number) =>
              isMobile ? `${value.toFixed(1)}` : `${(value).toFixed(2)}`,
          },
        },
      ],
    },
  };

  return (
    <div className="p-2 mt-2 border border-gray-200 rounded-md">
      <Chart {...chartConfig} />
    </div>
  );
};

export default BigChart;
